import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/sharp-light-svg-icons"
import MenuButton from "../../atoms/MenuButton"

// Suchergebnisse: Nur Kategorien
const SearchResultsCategories = ({ results }) => {
  const { t } = useTranslation()
  const [openCategoriesDropdownState, setOpenCategoriesDropdownState] =
    useState(false)

  if (results && results.length > 0) {
    return (
      <div className="top-0 pt-8 md:sticky">
        <div className="relative">
          <button
            className="w-full px-4 md:pb-1 border-b bg-ro-gray-50 border-ro-gray-400 md:w-auto md:bg-transparent md:border-transparent md:px-0 md:pointer-events-none"
            onClick={() =>
              setOpenCategoriesDropdownState(
                openCategoriesDropdownState ? false : true
              )
            }
          >
            <span className="flex flex-row justify-between py-3 leading-6 md:block md:font-bold md:py-1">
              {t("generic.search_results.categories")}{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                size="1x"
                className={`self-center inline-block md:hidden transition-transform duration-300 transform ${
                  openCategoriesDropdownState ? "-rotate-180" : ""
                }`}
              />
            </span>
          </button>
          <div
            className={`absolute ${
              openCategoriesDropdownState ? "block" : "hidden md:block"
            } max-h-[16.5rem] md:max-h-none overflow-y-auto inset-x-0 z-[70] bg-ro-gray-50 md:relative top-full md:bg-transparent shadow-[0_2px_6px_0_rgba(0,0,0,0.3)] md:shadow-none`}
          >
            {results.map((hit) => (
              <React.Fragment key={hit.url}>
                <Link
                  className="hidden md:block w-full h-8 text-ro-gray-600 md:py-1 md:px-0 md:text-ro-red hover:text-ro-red-700 hover:underline hover:underline-offset-4"
                  to={hit.full_slug}
                >
                  <span dangerouslySetInnerHTML={{ __html: hit.name }} />
                </Link>
                <MenuButton
                  className="flex text-left md:hidden"
                  link={hit.full_slug}
                >
                  <span dangerouslySetInnerHTML={{ __html: hit.name }} />
                </MenuButton>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default SearchResultsCategories
