import React from "react"

const Headline = ({
  headline,
  headlineLevel = "h2",
  tagName,
  className,
  ...props
}) => {
  const HTag =
    tagName && tagName.length > 0
      ? tagName
      : headlineLevel && headlineLevel.length > 0
      ? headlineLevel
      : "h2"
  return (
    <HTag
      className={`headline headline-${headlineLevel} relative inline-block py-2 first:pt-0 first:pb-2 ${
        className ? className : ""
      }`}
      {...props}
      dangerouslySetInnerHTML={{
        __html: headline.replace("|", "<br />"),
      }}
    />
  )
}
export default Headline
