import React, { useRef, useState, useEffect } from "react"
import { Image, Transformation } from "cloudinary-react"

const CloudinaryImage = ({
  image,
  type,
  imageWidth,
  imageHeight,
  fillColor = "ffffff",
  gravity,
  className,
  alt,
  title,
  aspectRatio = null,
  lazyLoad = true,
}) => {
  const [imgWidth, setImgWidth] = useState(imageWidth)
  const [imgHeight, setImgHeight] = useState(imageHeight)
  const [loading, setLoading] = useState(true)

  const containerRef = useRef(null)

  const calcContainerWidth = () => {
    const width = containerRef.current.clientWidth
    return Math.ceil(width / 50) * 50
  }
  const calcContainerHeight = () => {
    const height = containerRef.current.clientHeight
    return Math.ceil(height / 50) * 50
  }

  const ar = aspectRatio?.length > 0 ? aspectRatio.replace("by", ":") : ""

  useEffect(() => {
    if (containerRef.current) {
      let containerWidth = calcContainerWidth()
      let containerHeight = calcContainerHeight()
      if (!imageWidth) calcContainerWidth()
      if (!imageHeight) calcContainerHeight()

      if (imageWidth === "auto") {
        setImgWidth("")
        setImgHeight(containerHeight)
      } else {
        setImgWidth(containerWidth)
        setImgHeight("")
      }

      setLoading(false)
    }
  }, [containerRef, calcContainerWidth, calcContainerHeight, loading])

  return (
    <div ref={containerRef} className={className}>
      {loading === false && (
        <Image
          cloudName={process.env.CLOUDINARY_NAME}
          publicId={image}
          type="fetch"
          className={className}
          alt={alt}
          title={title ? title : alt}
          loading={lazyLoad ? "lazy" : "eager"}
          secure={true}
        >
          <Transformation
            quality="auto:good"
            gravity={gravity ? gravity : fillColor ? "" : "face"}
            width={imgWidth}
            height={imgHeight}
            background={fillColor ? `#${fillColor}` : ""}
            crop={fillColor ? "pad" : "fill"}
            dpr="auto"
            aspectRatio={ar}
          />
          <Transformation fetchFormat="auto" />
        </Image>
      )}
    </div>
  )
}

export default CloudinaryImage
