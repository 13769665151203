import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
//import RatingStars from "../atoms/RatingStars"

const ProductCard = ({ item }) => {
  if (item.image === "undefined") {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  return (
    <Link className="flex flex-col w-full" link={item.link}>
      <div className="border border-ro-gray-100 aspect-square block w-full">
        <Image
          className="w-full h-auto min-h-full"
          image={item.image}
          aspectRatio="1by1"
          fitIn="true"
          fillColor="ffffff"
        />
      </div>
      <div className="flex-1 p-4 -mt-px border border-ro-gray-100">
        <div>
          <span className="font-bold text-ro-red hyphens-auto">
            {item.headline}
          </span>
          <p className="mt-2 prose-sm text-ro-gray-500">{item.text}</p>
        </div>
        {/* <div className="flex flex-row justify-between">
           <span className="text-xs text-ro-gray-500 ">
            {item.subline}
          </span>
        </div> */}
      </div>
    </Link>
  )
}

export default ProductCard
