import React, { useRef, useState, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
// import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRotate, faSearch, faXmark } from "@fortawesome/sharp-light-svg-icons"

import useDebounce from "../../../../hooks/useDebounce"
import quickLinksMapping from "gatsby-theme-rothenberger/config/quickLinks.js"
import MenuButton from "../atoms/MenuButton"
import { navigate } from "gatsby"

import { connectStateResults } from "react-instantsearch-core"

const AlgoliaSearchState = connectStateResults(
  ({ isSearchStalled, searching }) => {
    return (
      <>
        {" "}
        {isSearchStalled ? (
          <FontAwesomeIcon
            icon={faRotate}
            size="1x"
            className="w-4 h-4 animate-spin transform rotate-180 inline-block align-middle"
          />
        ) : (
          <FontAwesomeIcon icon={faSearch} size="1x" className="w-4 h-4" />
        )}
      </>
    )
  }
)

const Search = ({
  setShowMenuState,
  categories,
  setShowSearchResultsState,
  showSearchResultsState,
  setShowMobileSearchState = () => {},
  setQuery,
  query,
  isMobile = false,
}) => {
  const inputElement = useRef(null)

  const [tmpQuery, setTmpQuery] = useState()
  const debouncedQuery = useDebounce(tmpQuery, 500)
  useEffect(() => {
    if (debouncedQuery !== undefined) {
      // console.warn("Set debouncedQuery: ", debouncedQuery)
      setQuery(debouncedQuery)
      if (typeof window !== "undefined") {
        // console.warn("Navigate: ", debouncedQuery)
        // navigate(`${window.location.pathname.substr(0,7)}?query=${encodeURI(debouncedQuery)}`)
      }
    }
  }, [debouncedQuery, setQuery])

  const [showQuicklinksState, setShowQuicklinksState] = useState(false)

  const SearchFocusHandler = (element) => {
    setShowMenuState(null)
    if (element?.target?.value === "") {
      setShowQuicklinksState(true)
    }
  }

  const cancelSearchHandler = () => {
    setTmpQuery(null)
    setQuery(null)
    setShowSearchResultsState(false)
    setShowMobileSearchState(false)
    setShowQuicklinksState(false)
    if (inputElement.current) {
      inputElement.current.value = ""
    }
  }

  useEffect(() => {
    if (inputElement.current && isMobile) {
      inputElement.current.focus()
    }
  }, [])

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const website = `${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${i18n.language}`
  const quickLinks = quickLinksMapping["quickLinks"][website]

  return (
    <div
      className={`flex flex-row flex-grow ${isMobile ? "" : " px-4"} md:py-4`}
    >
      <form
        className="relative w-full text-ro-gray-900 border-b border-ro-gray-400 bg-ro-gray-50 h-12"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <button
          className="absolute left-0 flex items-center justify-center w-12 h-12"
          type="submit"
        >
          <AlgoliaSearchState />
        </button>
        <input
          name="query"
          className="h-12 transition-shadow duration-300 inline-block w-full py-3 pl-12 md:pt-3 md:pb-[0.6875rem] pr-4 leading-6 placeholder-ro-gray-300 bg-transparent focus:ro-ghost-red-double-border"
          id="searchBox"
          ref={inputElement}
          type="text"
          placeholder={t("generic.search.placeholder")}
          autoComplete="off"
          defaultValue={query}
          onFocus={(e) => {
            SearchFocusHandler(e)
          }}
          onBlur={() => {
            if (!isMobile) {
              setTimeout(() => {
                setShowQuicklinksState(false)
              }, 150)
            }
          }}
          onChange={(e) =>
            e.target.value !== undefined
              ? (setTmpQuery(e.target.value),
                setShowQuicklinksState(false),
                setShowSearchResultsState(true))
              : ""
          }
        />
        {(showQuicklinksState || showSearchResultsState) && (
          <button
            onClick={() => cancelSearchHandler()}
            className="absolute right-0 top-0 flex items-center justify-center w-12 h-12"
          >
            <FontAwesomeIcon icon={faXmark} size="1x" className="w-4 h-4" />
          </button>
        )}
        {/* Quicklinks */}

        {quickLinks && quickLinks.length > 0 && (
          <div
            className={`${
              showQuicklinksState ? "block" : "hidden"
            } absolute z-[60] text-sm w-full top-[calc(100%+1px)] ro-search-box-shadow bg-ro-gray-50`}
          >
            {quickLinks.map((quickLink) => (
              <MenuButton key={quickLink.url} link={quickLink.url}>
                {quickLink.name}
              </MenuButton>
            ))}
          </div>
        )}
        {/* ENDE Quicklinks */}
      </form>
    </div>
  )
}

export default Search
