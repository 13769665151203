import React, { useState, useEffect } from "react"
import { getCookie, setCookie } from "react-use-cookie"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const LangHint = ({}) => {
  const location = useLocation()
  const { i18n, t } = useTranslation()

  let websiteLangCodeStr = ""
  let websiteCookieName = ""

  // wird für i18n und das Cookie benötigt
  if (
    process.env.GATSBY_USE_FOLDER_AS_LANGUAGE === "true" &&
    process.env.GATSBY_GLOBAL_PATH_PREFIX
  ) {
    websiteLangCodeStr =
      process.env.GATSBY_GLOBAL_PATH_PREFIX + "_" + i18n.language
    websiteCookieName = "redirect_" + websiteLangCodeStr
  } else if (process.env.GATSBY_USE_FOLDER_AS_COUNTRY_AND_LANGUAGE === "true") {
    websiteLangCodeStr = location.pathname.split("/")[1].replace("-", "_")
    websiteCookieName = "redirect_corp_" + websiteLangCodeStr
  }

  // siehe: websites/www/static/_redirects
  const websiteCookie = getCookie(websiteCookieName)

  // => sobald dieses Cookie vorhanden ist, soll der Hinweis nicht mehr angezeigt werden
  const [showLangHint, setShowLangHint] = useState(false)

  const acceptWebsite = () => {
    setCookie(websiteCookieName, "d", {
      days: 365,
      SameSite: "Lax",
    })
    setShowLangHint(false)
  }

  useEffect(() => {
    if (!websiteCookie) {
      setShowLangHint(true)
    } else {
      setShowLangHint(false)
    }
  }, [websiteCookie])

  if (process.env.GATSBY_USE_FOLDER_AS_COUNTRY_AND_LANGUAGE === "true") {
    return null
  }

  return (
    <>
      {showLangHint && (
        <div className="sticky z-40 w-full bottom-[4.5rem]">
          <div className="relative block-w-container">
            <div className="absolute bottom-0 right-0 z-40 px-4 py-8 border border-t-0 bg-ro-gray-100 border-ro-gray-100 max-w-screen md:max-w-md">
              <p className="block text-base">
                {t("websites_hint." + websiteLangCodeStr + ".hint")}
              </p>
              <Link
                className="py-2 mt-4 mr-4 btn btn-secondary"
                link="https://www.rothenberger.com/websites"
              >
                {t(
                  "websites_hint." +
                    websiteLangCodeStr +
                    ".button_change_country_yes"
                )}
              </Link>
              <button
                onClick={() => acceptWebsite()}
                className="py-2 mt-4 btn btn-primary"
              >
                {t(
                  "websites_hint." +
                    websiteLangCodeStr +
                    ".button_change_country_no"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LangHint
