import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/sharp-light-svg-icons"

import { connectHits } from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

import SearchResultsVariants from "./SearchResults/SearchResultsVariants"
import SearchResultsProducts from "./SearchResults/SearchResultsProducts"
import SearchResultsCategories from "./SearchResults/SearchResultsCategories"
import SearchResultsNews from "./SearchResults/SearchResultsNews"
import SearchResultsPages from "./SearchResults/SearchResultsPages"

// @Redesign: Translation fehlt (mehrfach) für die Anzahl der Ergebnisse.

// Nur zu Debug-Zwecken, um ALLE Such-Ergebnisse zu sehen
const SearchResultsAll = connectHits(({ hits }) => {
  return (
    <>
      {hits.map((hit) => (
        <div>
          {JSON.stringify(hit)}
          <br />
          <br />
        </div>
      ))}
    </>
  )
})

// Konvertiert anhand des 'type' in einen Anzeige-Tab
const typeToTab = (hit) => {
  let type = ""
  // Alle von diesem Typ kommen in den Tab "Produkte (+ mehr)"
  if (
    [
      "product_area",
      "product_line",
      "product_group",
      "product",
      "variant",
    ].includes(hit.type)
  ) {
    type = "products_and_more"
  } else {
    type = hit.type
  }
  return type
}
const tabToTypes = (tab) => {
  let types = []
  if (tab === "products_and_more") {
    types = [
      "product_area",
      "product_line",
      "product_group",
      "product",
      "variant",
    ]
  } else {
    types = [tab]
  }
  return types
}

const SearchTabs = connectHits(
  ({ hits, resultsCategoryState, setResultsCategoryState, query }) => {
    const { t } = useTranslation()

    let c = []
    let cc = []
    hits.forEach((hit) => {
      let type = typeToTab(hit)
      if (!c.includes(type)) {
        c.push(type)
        cc.push({
          name: type,
          count: hits.filter((h) => tabToTypes(type).includes(h.type)).length,
        })
      }
    })

    useEffect(() => {
      if (hits && hits[0]) {
        // console.warn('Set state', hits[0])
        // setResultsCategoryState( typeToTab(hits[0]) )

        if (cc.length === 1) {
          setResultsCategoryState(cc[0].name)
        }
      }
    }, [query, hits])

    let ccSorted = []
    const catProducts = cc.find((c) => c.name === "products_and_more")
    const catNews = cc.find((c) => c.name === "news")
    const catPages = cc.find((c) => c.name === "page")
    if (catProducts) ccSorted.push(catProducts)
    if (catNews) ccSorted.push(catNews)
    if (catPages) ccSorted.push(catPages)

    return (
      <>
        {ccSorted.map((cat, index) => (
          <button
            key={cat.name}
            className={`flex items-center h-12 border-transparent px-grid border-y-2 transition-colors duration-300 whitespace-nowrap ${
              resultsCategoryState === cat.name
                ? "bg-ro-white border-t-ro-red"
                : "relative text-ro-gray-600 bg-ro-gray-100 border-ro-gray-100 btn hover:bg-ro-gray-150 hover:border-ro-gray-150 hover:text-ro-gray-900 before:block before:absolute before:transition-none before:left-0 before:-inset-y-1 before:w-px [&.bg-ro-gray-100+.bg-ro-gray-100]:before:bg-ro-gray-400"
            }
              `}
            onClick={() => {
              setResultsCategoryState(cat.name)
            }}
          >
            <>
              {t(`generic.tabs.${cat.name}`)}
              &nbsp; ({cat.count})
            </>
          </button>
        ))}
      </>
    )
  }
)

const SearchResultsOverlay = connectHits(
  ({
    hits,
    showSearchResultsState,
    setShowSearchResultsState,
    isMobile = false,
    query,
  }) => {
    const [resultsCategoryState, setResultsCategoryState] =
      useState("products_and_more")
    const [resultsPrepared, setResultsPrepared] = useState(false)
    const [resultMap, setResultMap] = useState({
      pageResults: null,
      newsResults: null,
      productResults: null,
      variantResults: null,
      categoryResults: null,
    })
    const { t } = useTranslation()

    const [mobileScroll, setMobileScroll] = useState(0)
    const [isScrollable, setIsScrollable] = useState(false)
    const refContainer = useRef()

    useEffect(() => {
      if (showSearchResultsState && hits && hits.length > 0) {
        let pageResults = hits.filter((h) => h.type === "page") || null
        let newsResults = hits.filter((h) => h.type === "news") || null
        let productResults = hits.filter((h) => h.type === "product")
        let variantResults = hits.filter((h) => h.type === "variant")
        let categoryResults = hits.filter((h) =>
          ["product_line", "product_area", "product_group"].includes(h.type)
        )

        setResultMap({
          pageResults: pageResults,
          newsResults: newsResults,
          productResults: productResults,
          variantResults: variantResults,
          categoryResults: categoryResults,
        })

        setResultsPrepared(true)
      } else {
        setResultsPrepared(false)
      }

      setTimeout(() => {
        if (
          refContainer?.current?.scrollWidth >
          refContainer?.current?.clientWidth
        ) {
          setIsScrollable(true)
        }
      }, 500)
    }, [showSearchResultsState, hits])

    useEffect(() => {
      if (resultsPrepared === true) {
        if (resultMap.productResults.length === 0) {
          if (resultMap.newsResults.length === 0) {
            setResultsCategoryState("page")
          } else {
            setResultsCategoryState("news")
          }
        }
      }
    }, [resultsPrepared])

    function buttonScrollToValue(input) {
      setMobileScroll(input)
      refContainer.current.scrollLeft = input
    }

    return (
      <>
        {showSearchResultsState && resultsPrepared && (
          <>
            <Helmet
              htmlAttributes={{
                class: "overflow-x-hidden overflow-hidden",
              }}
              bodyAttributes={{
                class:
                  "theme-default font-body overflow-x-hidden overflow-hidden",
              }}
            />
            <div className="absolute z-[20] w-full bg-ro-gray-50 top-[calc(100%+3rem)] md:top-[calc(100%+4rem+2px)] h-[calc(100vh-8.5rem)] md:h-[calc(100vh-5rem)]">
              <div className="relative md:py-8 md:pt-10">
                <div className="relative w-full">
                  <div
                    className="flex flex-row mx-auto overflow-y-hidden max-w-screen-2xl md:px-6 scroll-smooth"
                    ref={refContainer}
                    onScroll={() =>
                      setMobileScroll(refContainer.current.scrollLeft)
                    }
                  >
                    <button
                      className={`${
                        isScrollable && mobileScroll > 0
                          ? "inline-block md:hidden"
                          : "hidden"
                      } absolute left-0 z-[1] flex items-center justify-center w-12 h-12 bg-ro-gray-100`}
                      onClick={() => buttonScrollToValue(0)}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                    </button>
                    <button
                      className={`${
                        isScrollable &&
                        mobileScroll <
                          refContainer?.current?.scrollWidth -
                            refContainer?.current?.clientWidth
                          ? "inline-block md:hidden"
                          : "hidden"
                      } absolute right-0 z-[1] flex items-center justify-center w-12 h-12 bg-ro-gray-100`}
                      onClick={() =>
                        buttonScrollToValue(refContainer?.current?.scrollWidth)
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} size="1x" />
                    </button>
                    <SearchTabs
                      resultsCategoryState={resultsCategoryState}
                      setResultsCategoryState={setResultsCategoryState}
                      query={query}
                    />
                  </div>
                </div>

                {resultsCategoryState === "page" && (
                  <div className="bg-ro-white h-[calc(100vh-11.5rem)] md:h-[calc(100vh-10.5rem)]">
                    <div className="overflow-y-auto h-[calc(100vh-11rem)] md:h-[calc(100vh-17.5rem)]">
                      <SearchResultsPages results={resultMap.pageResults} />
                    </div>
                  </div>
                )}

                {resultsCategoryState === "news" && (
                  <div className="bg-ro-white h-[calc(100vh-11.5rem)] md:h-[calc(100vh-10.5rem)]">
                    <div className="overflow-y-auto h-[calc(100vh-11rem)] md:h-[calc(100vh-17.5rem)]">
                      <SearchResultsNews results={resultMap.newsResults} />
                    </div>
                  </div>
                )}

                {resultsCategoryState === "products_and_more" && (
                  <div className="bg-ro-white md:pb-10 h-[calc(100vh-11.5rem)] md:h-[calc(100vh-10.5rem)]">
                    <div className="overflow-y-auto h-[calc(100vh-11rem)] md:h-[calc(100vh-17.5rem)]">
                      <div className="block-w-container">
                        <div className="flex flex-row flex-wrap -mx-grid">
                          <div className="w-full md:mb-8 md:w-1/3 lg:w-1/4 px-grid">
                            <SearchResultsCategories
                              results={resultMap.categoryResults}
                            />
                          </div>
                          <div className="w-full pt-6 pb-10 md:pb-8 md:w-2/3 lg:w-3/4 px-grid">
                            <SearchResultsProducts
                              results={resultMap.productResults}
                            />
                            <div className="my-6 border-b border-ro-gray-100"></div>
                            <SearchResultsVariants
                              results={resultMap.variantResults}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    )
  }
)

export default SearchResultsOverlay
