import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const websitesMapping = require("../../../../../config/websites.js")
const corporateServiceMappingByEnv =
  websitesMapping["corporateServiceMappingByEnv"]

const MetaNavCorporateSwitch = () => {
  const { t } = useTranslation()
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "production"
  const navContext = useContext(NavContext)
  const countryLang = navContext.countryLang
  const corporateMappingList = corporateServiceMappingByEnv[activeEnv]
  const activeMapping = corporateMappingList.find(
    (m) => m.countryLang === countryLang
  )
  const currentlyOnCorporate =
    process.env.GATSBY_IS_CORPORATE_WEBSITE === "true"

  return (
    <>
      <Link
        className={`py-[0.625rem] px-4 focus-visible:outline-ro-red whitespace-nowrap ${
          !currentlyOnCorporate
            ? "bg-ro-white pointer-events-none btn border-y-2 border-t-ro-red border-b-ro-white"
            : "text-ro-gray-600 bg-ro-gray-100 btn hover:bg-ro-gray-150 hover:text-ro-gray-900 border-y-2 border-y-ro-gray-100 hover:border-y-ro-gray-150"
        }`}
        link={activeMapping.serviceUrl}
        target="_self"
      >
        {t("generic.website_tabs.service")}
      </Link>
      <Link
        className={`py-[0.625rem] px-4 focus-visible:outline-ro-red whitespace-nowrap ${
          currentlyOnCorporate
            ? "bg-ro-white pointer-events-none btn border-y-2 border-t-ro-red border-b-ro-white"
            : "text-ro-gray-600 bg-ro-gray-100 btn hover:bg-ro-gray-150 hover:text-ro-gray-900 border-y-2 border-y-ro-gray-100 hover:border-y-ro-gray-150"
        }`}
        link={activeMapping.corporateUrl}
        target="_self"
      >
        {t("generic.website_tabs.corporate")}
      </Link>
    </>
  )
}

export default MetaNavCorporateSwitch
