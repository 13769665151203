import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ProductVariantCard from "../../../../../components/molecules/ProductVariantCard"

const SearchResultsVariants = ({ results }) => {
  const { t } = useTranslation()
  const [showResultLimit, setShowResultLimit] = useState(6)
  const [displayResults, setDisplayResults] = useState(results)

  const showMoreResultsHandler = (currentLimit) => {
    setShowResultLimit(currentLimit + currentLimit)
  }

  useEffect(() => {
    if (results && showResultLimit) {
      setDisplayResults(results.slice(0, showResultLimit))
    }
  }, [results, showResultLimit])

  if (displayResults && displayResults.length > 0) {
    return (
      <>
        <h4 className="text-xl font-bold py-2">{`${t(
          "generic.search_results.variants"
        )} (${results.length})`}</h4>
        <div className="flex flex-row flex-wrap -mx-grid">
          {displayResults.map((hit) => (
            <div
              className="flex w-full my-2 md:my-4 px-grid md:w-1/2 lg:w-1/3"
              key={hit._refObjectId}
            >
              <ProductVariantCard
                image={
                  hit.image_url !== "undefined"
                    ? hit.image_url
                    : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                }
                title={hit.name}
                text={`${t("generic.products.no")} ${hit.article_number}`}
                link={hit.full_slug}
              />
            </div>
          ))}
        </div>
        {displayResults &&
          displayResults.length > 0 &&
          showResultLimit < results.length && (
            <div className="w-full py-2 md:py-4 md:text-center">
              <div className="w-full mb-4">
                <span className="text-sm text-ro-gray-600">
                  {displayResults.length} von {results.length} Ergebnissen
                </span>
              </div>
              <button
                onClick={(e) => showMoreResultsHandler(showResultLimit)}
                className="w-full px-4 text-left md:text-center border md:w-auto btn border-ro-red text-ro-red hover:text-ro-white hover:bg-ro-red active:bg-ro-red-700 active:border-ro-red-700 active:text-ro-white"
              >
                {t("generic.search_results.show_more")}
              </button>
            </div>
          )}
      </>
    )
  } else {
    return <></>
  }
}

export default SearchResultsVariants
