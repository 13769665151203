import React, { useState } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { useTranslation } from "react-i18next"

import { privacyLinks } from "../../../../utilities/privacy-links"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronDown } from "@fortawesome/sharp-light-svg-icons"
import {
  faFacebook,
  faInstagram,
  faInstagramSquare,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

const FooterWithColumns = ({ navData }) => {
  const { t, i18n } = useTranslation()
  const [mobileAccordionState, setMobileAccordionState] = useState("0-0")

  const metaNav = navData.meta ? navData.meta[0] : {}

  const openKlaro = () => {
    if (typeof window !== "undefined") {
      let countryLang = window.location.pathname.split("/")[1]

      return window?.klaro?.show({
        ...window.klaroConfig,
        ...{ lang: i18n.language },
        ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
      })
    }
  }

  return (
    <footer className="text-ro-gray-200 bg-ro-gray-900">
      {(navData?.newsletter_title?.length > 0 ||
        navData?.newsletter_text?.length > 0) && (
        <>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl px-grid lg:px-10">
            <div className="flex flex-row flex-wrap justify-between pt-10 pb-4 md:pb-8 md:pt-14">
              <div className="mb-6 md:mb-8 text-ro-gray-50">
                <span className="block mb-2 text-base font-bold text-ro-gray-50">
                  {navData.newsletter_title}
                </span>
                <span>{navData.newsletter_text}</span>
              </div>
              {navData?.newsletter_link_text?.length > 0 && (
                <Link
                  className="w-full mb-8 md:w-auto btn btn-outline-white h-fit"
                  link={navData.newsletter_link}
                >
                  {navData.newsletter_link_text}
                </Link>
              )}
            </div>
          </div>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl md:px-4 lg:px-10">
            <div className="w-full border-t border-ro-gray-700" />
          </div>
        </>
      )}
      {/* Sitemap */}
      {navData?.columns?.length > 0 && (
        <>
          <div className="mx-auto leading-6 max-w-screen-2xl px-grid lg:px-10">
            <div className="flex flex-row flex-wrap md:pt-16 md:pb-8 -mx-grid">
              {navData?.columns?.map((navCol, colIndex) => (
                <div
                  className="w-full md:mb-4 lg:mb-8 md:w-1/2 lg:w-1/4"
                  key={`footer-col-${colIndex}`}
                >
                  {navCol.items.map((item, index) => {
                    return (
                      <React.Fragment key={`footer-col-content-${index}`}>
                        {item.component === "nav_item" && (
                          <div className="border-b border-ro-gray-700 md:border-none md:mb-8">
                            {item?.link?.cached_url?.length > 0 ? (
                              <div
                                className={`${
                                  item?.sub_navigation?.length > 0
                                    ? "hidden md:block mb-1"
                                    : "block py-3 md:py-0"
                                } px-4`}
                              >
                                <Link
                                  className={`md:font-bold text-ro-gray-50`}
                                  link={item.link.cached_url}
                                >
                                  {item.title}
                                </Link>
                              </div>
                            ) : (
                              <p className="hidden px-4 mb-1 font-bold text-ro-gray-50 md:block">
                                {item.title}
                              </p>
                            )}
                            {item?.sub_navigation?.length > 0 && (
                              <>
                                <button
                                  className="flex w-full px-4 py-3 text-left text-ro-gray-50 md:hidden"
                                  onClick={() =>
                                    setMobileAccordionState(
                                      mobileAccordionState ===
                                        `${colIndex}-${index}`
                                        ? null
                                        : `${colIndex}-${index}`
                                    )
                                  }
                                >
                                  {item.title}
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="1x"
                                    className={`inline-block ml-auto transform transition-transform duration-300 self-center ${
                                      mobileAccordionState ===
                                      `${colIndex}-${index}`
                                        ? "-rotate-180"
                                        : ""
                                    }`}
                                  />
                                </button>
                                <div
                                  className={`${
                                    mobileAccordionState ===
                                    `${colIndex}-${index}`
                                      ? "block"
                                      : "hidden"
                                  } mb-6 md:mb-0 md:block`}
                                >
                                  {item.sub_navigation.map(
                                    (subItem, subIndex) => (
                                      <div
                                        key={subIndex}
                                        className="px-4 py-3 md:py-1"
                                      >
                                        <Link
                                          className="inline-block underline-offset-4 hover:text-ro-gray-50 hover:underline active:text-ro-gray-50 active:underline whitespace-nowrap focus:outline focus:outline-white"
                                          link={subItem.link.cached_url}
                                        >
                                          {subItem.title}
                                        </Link>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl md:px-4 lg:px-10">
            <div className="w-full border-t border-ro-gray-700 px-grid" />
          </div>
        </>
      )}
      {/* Meta-Nav */}
      <div className="mx-auto text-sm leading-6 max-w-screen-2xl px-grid lg:px-10">
        <div className="flex flex-row flex-wrap justify-between pt-8 pb-4 leading-6 md:pt-6 md:pb-6">
          <div className="flex flex-row flex-wrap items-center w-full pb-6 -mx-2 lg:w-auto">
            <span className="block w-full px-2 py-1 md:py-2 show-when-consent lg:order-first lg:w-auto">
              © {new Date().getFullYear()} ROTHENBERGER Werkzeuge GmbH
            </span>
            <button
              key="cookieManager"
              className="mx-2 underline-offset-4 hover:text-ro-gray-50 hover:underline active:text-ro-gray-50 active:underline whitespace-nowrap focus:outline focus:outline-white"
              onClick={() => {
                openKlaro()
              }}
            >
              {t("generic.user_consent.manage_cookies")}
            </button>
            {metaNav?.content?.length > 0 && (
              <>
                {metaNav.content?.map((content, index) => {
                  return (
                    <Link
                      key={index}
                      className="mx-2 underline-offset-4 show-when-consent hover:text-ro-gray-50 hover:underline active:text-ro-gray-50 active:underline whitespace-nowrap focus:outline focus:outline-white"
                      link={content.link.cached_url}
                    >
                      {content.title}
                    </Link>
                  )
                })}
              </>
            )}
          </div>
          <div className="flex flex-row flex-wrap pb-6 -mx-1 text-base">
            {navData.facebook_link?.cached_url?.length > 0 && (
              <Link
                className="w-10 h-10 p-0 mr-2 btn btn-outline-white flex items-center justify-center"
                link={navData.facebook_link}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="1x"
                  className="inline-block"
                />
              </Link>
            )}
            {navData.instagram_link?.cached_url?.length > 0 && (
              <Link
                className="w-10 h-10 p-0 mr-2 btn btn-outline-white flex items-center justify-center"
                link={navData.instagram_link}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className="inline-block"
                />
              </Link>
            )}
            {navData.youtube_link?.cached_url?.length > 0 && (
              <Link
                className="w-10 h-10 p-0 mr-2 btn btn-outline-white flex items-center justify-center "
                link={navData.youtube_link}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  size="1x"
                  className="inline-block"
                />
              </Link>
            )}
            {navData.linkedin_link?.cached_url?.length > 0 && (
              <Link
                className="w-10 h-10 p-0 btn btn-outline-white flex items-center justify-center "
                link={navData.linkedin_link}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="1x"
                  className="inline-block"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterWithColumns
