import React, { useContext, useEffect, useState } from "react"
// import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/Seo"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"
import { changeLanguage } from "i18next"

// should default to https://rothenberger.com/privacy

import { privacyLinks } from "../../../../utilities/privacy-links"
import { imprintLinks } from "../../../../utilities/imprint-links"
import LangHint from "./LangHint"
import Klaro from "../technical/Klaro"

const websitesRegionsMap = require("../../../../../config/websiteRegions")
const websiteRegionsMapping = websitesRegionsMap["websiteRegionsMapping"]

const toggleEtrackerCookie = (klaroManager) => {
  let etrackerAllowed = klaroManager.getConsent("gtm_analytics")
  if (etrackerAllowed === true) {
    if (window._etracker) {
      console.log("DEBUG enable etracker cookie")
      window._etracker.enableCookies(process.env.GATSBY_ETRACKER_COOKIE_DOMAIN)
    }
  } else {
    if (window._etracker && window._etracker.areCookiesEnabled()) {
      console.log("DEBUG disable etracker cookie")
      window._etracker.disableCookies(process.env.GATSBY_ETRACKER_COOKIE_DOMAIN)
    }
  }
}

const Layout = ({ children, language }) => {
  const navContext = useContext(NavContext)
  const navData = navContext?.navData || {}
  const [cookieConsentLight, setCookieConsentLight] = useState(false)

  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)
    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language
        let countryLang = window.location.pathname.split("/")[1]

        // check for impressum and datenschutz + dont block interaction with the site
        if (
          window.location.pathname.indexOf(privacyLinks[countryLang]) !== -1 ||
          window.location.pathname.indexOf(imprintLinks[countryLang]) !== -1
        ) {
          setCookieConsentLight(true)
        }

        setTimeout(() => {
          // if (countryLang !== "de-de") {
          //   window.klaroConfig.services = window.klaroConfig.services.filter(
          //     (el) => el.name !== "adformtracking"
          //   )
          //   window.klaroConfig.services = window.klaroConfig.services.filter(
          //     (el) => el.name !== "facebookpixel"
          //   )
          // }
          // window.klaro.render({
          //   ...window.klaroConfig,
          //   ...{ lang: language },
          //   ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
          // })
          // if (window.klaro.getManager) {
          //   let manager = window.klaro.getManager()
          //   manager.applyConsents()
          //   if (manager && manager.getConsent) {
          //     toggleEtrackerCookie(manager)
          //   }
          //   manager.watch({
          //     update: (data, type) => {
          //       if (type === "savedConsents") {
          //         toggleEtrackerCookie(manager)
          //       }
          //     },
          //   })
          // }
        }, 50)
      }

      if (document?.location?.pathname === "/storyblok-preview/") {
        setTimeout(() => {
          if (document.querySelector("#cookie-consent")) {
            console.log("DEBUG found consent in preview")
            document.querySelector("#cookie-consent").style =
              "visibility:hidden;"
          }
        }, 100)
      }
    }
  }, [language, children])

  return (
    <>
      <Header navData={navData} />

      {/* <SEO title="Test"></SEO> */}
      <Helmet
        htmlAttributes={{ lang: language }}
        bodyAttributes={{
          class: `theme-default ${cookieConsentLight ? "klaro-light" : ""}`,
        }}
      >
        <script type="application/ld+json">
          {`{
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "ROTHENBERGER",
            "url" : "https://rothenberger.com/"
          }`}
        </script>

        {websiteRegionsMapping &&
          websiteRegionsMapping.length > 0 &&
          websiteRegionsMapping.map((reg) => {
            return (
              <link
                key={reg.hrefLang}
                rel="alternate"
                href={reg.url}
                hrefLang={reg.hrefLang}
              />
            )
          })}
        <link
          rel="alternate"
          href="https://rothenberger.com/websites/"
          hrefLang="x-default"
        />
      </Helmet>
      <Klaro lang={language} />
      <main>{children}</main>
      <LangHint />
      <Footer navData={navData} />
    </>
  )
}

export default Layout
