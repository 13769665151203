import React from "react"
import { tv } from "tailwind-variants"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const menuButtonVariants = tv({
  base: "w-full px-4 h-12 transition-colors flex items-center duration-300 group",
  variants: {
    type: {
      default:
        "text-ro-gray-600 bg-transparent hover:bg-ro-gray-75 focus-visible:outline focus-visible:outline-ro-red focus-visible:outline-2 focus-visible:-outline-offset-2",
    },

    state: {
      default: "",
      disabled: "pointer-events-none",
    },
  },
  compoundVariants: [
    {
      type: "default",
      state: "disabled",
      class: "border-ro-gray-100 bg-ro-gray-100 text-ro-gray-300",
    },
  ],
  defaultVariants: { type: "default", state: "default" },
})

const MenuButton = ({
  children,
  className,
  btnType = "default",
  btnState = "default",
  link,
  target,
  ...props
}) => {
  let btnDisabledState = btnState === "disabled" ? { disabled: true } : {}

  if (link) {
    return (
      <Link
        className={menuButtonVariants({
          type: btnType,
          state: btnState,
          className: className,
        })}
        link={link}
        target={target}
        {...props}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={menuButtonVariants({
          type: btnType,
          state: btnState,
          className: className,
        })}
        {...btnDisabledState}
        {...props}
      >
        {children}
      </button>
    )
  }
}

export default MenuButton
