import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import DefaultCard from "../../../../../components/molecules/DefaultCard"
import MemoizedComponentWrapper from "../../technical/MemoizedComponentWrapper"

// Suchergebnisse: Nur Seiten (ohne News)
const SearchResultsPages = ({ results }) => {
  const { t } = useTranslation()
  const [showResultLimit, setShowResultLimit] = useState(8)
  const [displayResults, setDisplayResults] = useState(results)

  const showMoreResultsHandler = (currentLimit) => {
    setShowResultLimit(currentLimit + currentLimit)
  }

  useEffect(() => {
    if (results && showResultLimit) {
      setDisplayResults(results.slice(0, showResultLimit))
    }
  }, [results, showResultLimit])
  if (displayResults && displayResults.length > 0) {
    return (
      <div className="block-w-container py-6">
        <div className="flex flex-row flex-wrap -mx-grid">
          {displayResults.map((hit) => (
            <div
              className="flex w-full my-2 md:my-4 px-grid md:w-1/3 lg:w-1/4"
              key={hit._refObjectId}
            >
              <MemoizedComponentWrapper value={hit}>
                <DefaultCard
                  image={{ filename: hit.image_url }}
                  title={hit.headline}
                  text={hit.description}
                  link={hit.full_slug}
                  link_text="Mehr erfahren"
                />
              </MemoizedComponentWrapper>
            </div>
          ))}
        </div>
        {displayResults &&
          displayResults.length > 0 &&
          showResultLimit < results.length && (
            <div className="w-full py-2 md:py-4 md:text-center">
              <div className="w-full mb-4">
                <span className="text-sm text-ro-gray-600">
                  {displayResults.length} von {results.length} Ergebnissen
                </span>
              </div>
              <button
                onClick={(e) => showMoreResultsHandler(showResultLimit)}
                className="w-full px-4 text-left md:text-center border md:w-auto btn border-ro-red text-ro-red hover:text-ro-white hover:bg-ro-red active:bg-ro-red-700 active:border-ro-red-700 active:text-ro-white"
              >
                {t("generic.search_results.show_more")}
              </button>
            </div>
          )}
      </div>
    )
  } else {
    return <></>
  }
}

export default SearchResultsPages
