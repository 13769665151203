import React from "react"

const MemoizedComponentWrapper = React.memo(
  ({ children, value }) => {
    return children
  },
  (prev, next) => prev.value === next.value
)

export default MemoizedComponentWrapper
