import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import { Card, CardHead, CardBody } from "./Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const IconCard = ({
  icon,
  title,
  text,
  link,
  link_text,
  type,
  className,
  showArrow = false,
}) => {
  return (
    <Card
      type={type}
      link={link}
      className={`flex flex-col w-full h-full group ${className}`}
    >
      <CardBody className="flex flex-col justify-between h-full">
        <CardHead className="mb-4">
          {icon?.filename?.length > 0 && (
            <Image
              className="inline-block object-contain h-10"
              fixedSize="0x40"
              image={icon}
            />
          )}
        </CardHead>
        <div className="flex flex-col justify-between h-full">
          <div className="mb-6">
            {title?.length > 0 && (
              <span className="font-bold line-clamp-3">{title}</span>
            )}
            {text?.length > 0 && (
              <span className="mt-2 text-gray-600 line-clamp-4">{text}</span>
            )}
          </div>
          <div className="flex flex-row text-ro-red group-hover:text-ro-red-700 ">
            {link_text?.length > 0 && (
              <span className="mr-2 line-clamp-1 underline-offset-4 group-hover:underline">
                {link_text}
              </span>
            )}
            {showArrow === true && (
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                className="self-center inline-block"
              />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default IconCard
