import React, { useState, useEffect } from "react"
import StaticImage from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Images/StaticImage"
import StoryblokImage from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Images/StoryblokImage"
import CloudinaryImage from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Images/CloudinaryImage"

const Image = ({
  image,
  className,
  style,
  fixedSize,
  aspectRatio,
  fitIn,
  forceLoad,
  imageWidth,
  imageHeight,
  fillColor,
  gravity,
  alt,
  title,
  lazyLoad,
  adaptToPixelRatio,
}) => {
  let imageType = null
  let imageSrc = ""

  image?.filename ? (imageSrc = image.filename) : (imageSrc = image)

  if (/^(?=.*\bstoryblok\b).*$/.test(imageSrc)) {
    imageType = "sbImage"
  } else if (/^(?=.*\bcontentserv\b).*$/.test(imageSrc)) {
    imageType = "cloudinaryImage"
  } else if (/^(?=.*\bshopify\b).*$/.test(imageSrc)) {
    imageType = "cloudinaryImage"
  } else if (/^(?=.*\bres.cloudinary.com\/rothenberger\b).*$/.test(imageSrc)) {
    imageType = "cloudinaryImage"
  } else {
    imageType = "staticImage"
  }

  const IMAGE_TYPES = {
    sbImage: (
      <StoryblokImage
        image={image}
        title={image.title}
        alt={image.alt}
        copyright={image.copyright}
        className={className}
        style={style}
        fitIn={fitIn}
        fixedSize={fixedSize}
        aspectRatio={aspectRatio}
        forceLoad={forceLoad}
        adaptToPixelRatio={adaptToPixelRatio}
      />
    ),
    staticImage: (
      <StaticImage
        image={imageSrc}
        title={image.title}
        alt={image.alt}
        copyright={image.copyright}
        className={className}
        style={style}
        forceLoad={forceLoad}
      />
    ),
    cloudinaryImage: (
      <CloudinaryImage
        image={imageSrc}
        alt={alt || image.alt}
        title={title}
        className={className}
        style={style}
        type={imageType}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        fillColor={fillColor}
        gravity={gravity}
        aspectRatio={aspectRatio}
        lazyLoad={lazyLoad}
      />
    ),
  }

  const ImageState = ({ imageType }) => {
    return <>{IMAGE_TYPES[imageType]}</>
  }

  return (
    <>
      <ImageState imageType={imageType} />
    </>
  )
}

export default Image
