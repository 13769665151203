import React from "react"
import { Card, CardBody, CardHead } from "./Card"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import { tv } from "tailwind-variants"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

import MemoizedComponentWrapper from "../../@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

const imageVariants = tv({
  base: "w-full",
  variants: {
    aspectRatio: {
      "4by3": "aspect-[4/3]",
      "16by9": "aspect-[16/9]",
      "1by1": "aspect-[1/1]",
    },
  },
})

const DefaultCard = ({
  image,
  image_fitin = false,
  image_ratio = "16by9",
  kicker,
  title,
  text,
  link,
  link_text,
  type,
  className,
}) => {
  let hasLink = link.url?.length !== 0 || link.cached_url?.length !== 0

  return (
    <Card
      type={type}
      link={link}
      className={`flex flex-col w-full h-full group ${
        hasLink ? "" : "pointer-events-none"
      } ${className?.length > 0 ? className : ""}`}
    >
      <CardHead type={image_fitin ? "paddedTop" : "default"} className="">
        <div className={image_fitin ? "py-5 px-7 bg-ro-white" : ""}>
          <MemoizedComponentWrapper value={image}>
            <Image
              image={image}
              aspectRatio={image_ratio}
              className={imageVariants({
                aspectRatio: image_ratio,
              })}
              fitIn={image_fitin}
            />
          </MemoizedComponentWrapper>
        </div>
      </CardHead>
      <CardBody className="flex flex-col justify-between h-full">
        <div className="">
          {kicker?.length > 0 && (
            <span className="block mb-2 text-sm text-ro-red-500 group-hover:text-ro-red-700">
              {kicker}
            </span>
          )}
          {title?.length > 0 && (
            <span className="font-bold line-clamp-3">{title}</span>
          )}
          {text?.length > 0 && (
            <span className="mt-2 text-gray-900 line-clamp-4">{text}</span>
          )}
        </div>
        {hasLink && (
          <div className="flex flex-row mt-6 text-ro-red group-hover:text-ro-red-700">
            {link_text?.length > 0 && (
              <span className="mr-2 line-clamp-1 underline-offset-4 group-hover:underline">
                {link_text}
              </span>
            )}
            <FontAwesomeIcon
              icon={faArrowRight}
              size="1x"
              className="self-center inline-block"
            />
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default DefaultCard
