import React, { useState } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { useTranslation } from "react-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronDown } from "@fortawesome/sharp-light-svg-icons"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

import { privacyLinks } from "../../../../utilities/privacy-links"

const FooterWithColumns = ({ navData }) => {
  const { t, i18n } = useTranslation()
  const [mobileAccordionState, setMobileAccordionState] = useState("0-0")

  // const metaNav = navData.meta ? navData.meta[0] : {}

  const navColumnArray =
    navData?.filter((item) => {
      return item.component !== "meta_nav"
    }) || []
  const metaNav =
    navData?.find((item) => {
      return item.component === "meta_nav"
    }) || null

  let navColumns = []
  let lastColIndex = navColumnArray?.length - 2

  navColumnArray?.forEach((item, index) => {
    if (index === 0) {
      if (navColumnArray[1]) {
        navColumns.push({ navItems: [navColumnArray[0], navColumnArray[1]] })
      } else {
        navColumns.push({ navItems: [navColumnArray[0]] })
      }
    } else if (index === lastColIndex) {
      navColumns.push({
        navItems: [
          navColumnArray[lastColIndex],
          navColumnArray[lastColIndex + 1],
        ],
      })
    } else if (
      index >= 2 &&
      index < lastColIndex &&
      item.component === "nav_item"
    ) {
      navColumns.push({ navItems: [item] })
    }
  })

  const openKlaro = () => {
    if (typeof window !== "undefined") {
      let countryLang = window.location.pathname.split("/")[1]

      return window?.klaro?.show({
        ...window.klaroConfig,
        ...{ lang: i18n.language },
        ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
      })
    }
  }

  return (
    <footer className="text-ro-gray-200 bg-ro-gray-900">
      {(navData?.newsletter_title?.length > 0 ||
        navData?.newsletter_text?.length > 0) && (
        <>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl px-grid">
            <div className="flex flex-row flex-wrap justify-between pt-10 pb-4 md:pb-8 md:pt-14">
              <div className="mb-8">
                <span className="block mb-2 text-base font-bold text-ro-gray-50">
                  {navData.newsletter_title}
                </span>
                <span>{navData.newsletter_text}</span>
              </div>
              {navData?.newsletter_link_text?.length > 0 && (
                <Link
                  className="mb-8 btn btn-outline-white h-fit"
                  link={navData.newsletter_link}
                >
                  {navData.newsletter_link_text}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    size="1x"
                    className="inline-block w-4 h-4 ml-2"
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl md:px-4">
            <div className="w-full border-t border-ro-gray-700" />
          </div>
        </>
      )}
      {/* Sitemap */}
      {navColumns?.length > 0 && (
        <>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl px-grid">
            <div className="flex flex-row flex-wrap md:pt-16 md:pb-8 -mx-grid">
              {navColumns?.map((navCol, colIndex) => {
                return (
                  <div
                    className="w-full md:mb-4 md:w-1/2 lg:w-1/4"
                    key={`footer-col-${colIndex}`}
                  >
                    {navCol.navItems.map((item, index) => {
                      return (
                        <React.Fragment key={`footer-col-content-${index}`}>
                          {item.component === "nav_item" && (
                            <div className="border-b border-ro-gray-700 md:border-none md:mb-8">
                              {item?.link?.cached_url?.length > 0 ? (
                                <Link
                                  className={`${
                                    item?.sub_navigation?.length > 0
                                      ? "hidden md:block mb-4"
                                      : "block py-3 md:py-0"
                                  } px-4 font-bold text-ro-gray-50`}
                                  link={item.link.cached_url}
                                >
                                  {item.title}
                                </Link>
                              ) : (
                                <p className="hidden px-4 mb-4 font-bold text-ro-gray-50 md:block">
                                  {item.title}
                                </p>
                              )}
                              {item?.sub_navigation?.length > 0 && (
                                <>
                                  <button
                                    className="flex w-full px-4 py-3 font-bold text-left text-ro-gray-50 md:hidden"
                                    onClick={() =>
                                      setMobileAccordionState(
                                        mobileAccordionState ===
                                          `${colIndex}-${index}`
                                          ? null
                                          : `${colIndex}-${index}`
                                      )
                                    }
                                  >
                                    {item.title}
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      size="1x"
                                      className={`inline-block ml-auto transform transition-transform duration-300 ${
                                        mobileAccordionState ===
                                        `${colIndex}-${index}`
                                          ? "-rotate-180"
                                          : ""
                                      }`}
                                    />
                                  </button>
                                  <div
                                    className={`${
                                      mobileAccordionState ===
                                      `${colIndex}-${index}`
                                        ? "block"
                                        : "hidden"
                                    } mb-6 md:mb-0 md:block`}
                                  >
                                    {item.sub_navigation.map(
                                      (subItem, index) => (
                                        <div key={index}>
                                          <Link
                                            className="block px-4 mb-4 hover:text-ro-gray-50"
                                            link={subItem.link.cached_url}
                                          >
                                            {subItem.title}
                                          </Link>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mx-auto text-sm leading-6 max-w-screen-2xl md:px-4">
            <div className="w-full border-t border-ro-gray-700 px-grid" />
          </div>
        </>
      )}
      {/* Meta-Nav */}
      <div className="mx-auto text-sm leading-6 max-w-screen-2xl px-grid">
        <div className="flex flex-row flex-wrap justify-between pt-8 pb-4 text-xs leading-6 md:pt-6 md:pb-6">
          <div className="flex flex-row flex-wrap w-full pb-6 -mx-2 lg:w-auto">
            <span className="block w-full px-2 py-1 md:py-2 show-when-consent lg:order-first lg:w-auto">
              © {new Date().getFullYear()} ROTHENBERGER Werkzeuge GmbH
            </span>
            <button
              key="cookieManager"
              className="px-2 py-1 md:py-2 hover:text-ro-gray-100 whitespace-nowrap"
              onClick={() => {
                openKlaro()
              }}
            >
              {t("generic.user_consent.manage_cookies")}
            </button>
            {metaNav?.content?.length > 0 && (
              <>
                {metaNav.content?.map((content, index) => {
                  return (
                    <Link
                      key={index}
                      className="px-2 py-1 md:py-2 show-when-consent hover:text-ro-gray-100 whitespace-nowrap"
                      link={content.link.cached_url}
                    >
                      {content.title}
                    </Link>
                  )
                })}
              </>
            )}
          </div>
          <div className="flex flex-row flex-wrap pb-6 -mx-1">
            {navData.facebook_link?.cached_url?.length > 0 && (
              <Link
                className="block p-3 mx-1 text-base leading-none btn btn-outline-white "
                link={navData.facebook_link}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="1x"
                  className="inline-block w-4 h-4"
                />
              </Link>
            )}
            {navData.instagram_link?.cached_url?.length > 0 && (
              <Link
                className="block p-3 mx-1 text-base leading-none btn btn-outline-white group"
                link={navData.instagram_link}
              >
                <div className=" w-[16px] h-[16px] bg-ro-white relative rounded mt-px ml-px group-hover:bg-ro-gray-900">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="1x"
                    className="absolute -top-[1.5px] -left-[1.5px] inline-block w-[19px] h-[19px] text-ro-gray-900 group-hover:text-ro-white"
                  />
                </div>
              </Link>
            )}
            {navData.youtube_link?.cached_url?.length > 0 && (
              <Link
                className="block p-3 mx-1 text-base leading-none btn btn-outline-white "
                link={navData.youtube_link}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  size="1x"
                  className="inline-block w-4 h-4"
                />
              </Link>
            )}
            {navData.linkedin_link?.cached_url?.length > 0 && (
              <Link
                className="block p-3 mx-1 text-base leading-none btn btn-outline-white "
                link={navData.linkedin_link}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="1x"
                  className="inline-block w-4 h-4"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterWithColumns
