import React, { useEffect, useRef, useState } from "react"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import MetaNavCorporateSwitch from "./MetaNavCorporateSwitch"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faGlobe,
} from "@fortawesome/sharp-light-svg-icons"

const websitesMapping = require("../../../../../config/websites.js")
const websites = websitesMapping["websitesMapping"]

const MetaNav = () => {
  const [currentWebsite, setCurrentWebsite] = useState(null)
  const [mobileScroll, setMobileScroll] = useState(0)
  const [isScrollable, setIsScrollable] = useState(false)
  const refContainer = useRef()

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentWebsite(
        websites.find(
          (w) =>
            w.url.replace("https://rothenberger.com", "").split("/")[1] ===
            window.location.pathname.split("/")[1]
        ) || null
      )
    }
    setTimeout(() => {
      if (
        refContainer?.current?.scrollWidth > refContainer?.current?.clientWidth
      ) {
        setIsScrollable(true)
      }
    }, 500)
  }, [])

  function buttonScrollToValue(input) {
    setMobileScroll(input)
    refContainer.current.scrollLeft = input
  }

  if (!currentWebsite) {
    return null
  }

  // @Redesign: Translation fehlt

  return (
    <div className="relative w-full bg-ro-gray-50">
      <div
        className="flex flex-row mx-auto overflow-x-auto lg:pl-6 lg:pr-10 max-w-screen-2xl scroll-smooth"
        ref={refContainer}
        onScroll={() => setMobileScroll(refContainer.current.scrollLeft)}
      >
        <button
          className={`${
            isScrollable && mobileScroll > 0
              ? "inline-block md:hidden"
              : "hidden"
          } absolute left-0 flex items-center justify-center w-12 h-12 bg-ro-gray-100`}
          onClick={() => buttonScrollToValue(0)}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="1x" />
        </button>
        <button
          className={`${
            isScrollable &&
            mobileScroll <
              refContainer?.current?.scrollWidth -
                refContainer?.current?.clientWidth
              ? "inline-block md:hidden"
              : "hidden"
          } absolute right-0 flex items-center justify-center w-12 h-12 bg-ro-gray-100`}
          onClick={() =>
            buttonScrollToValue(refContainer?.current?.scrollWidth)
          }
        >
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </button>
        <MetaNavCorporateSwitch />
        <div className="hidden ml-auto md:inline-block">
          <Link
            link="https://rothenberger.com/websites"
            className="flex items-center h-12 border-transparent px-grid border-y-2 hover:bg-ro-gray-100 hover:border-ro-gray-100 active:bg-ro-gray-200 active:border-ro-gray-200 focus-visible:-outline-offset-2 focus-visible:outline-ro-red whitespace-nowrap"
          >
            Land ändern
            <div className="flex items-center justify-center w-5 ml-2 h-11">
              <FontAwesomeIcon
                icon={faGlobe}
                size="1x"
                className="inline-block"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MetaNav
