import React from "react"
import { Card, CardBody, CardHead } from "./Card"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import MemoizedComponentWrapper from "../../@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

// @Redesign: Translation fehlt + Daten zur Anzahl der zugehörigen Varianten fehlt.

const ProductVariantCard = ({
  image,
  kicker,
  title,
  text,
  badge_text,
  link,
  type,
  className,
}) => {
  return (
    <Card
      link={link}
      className={`flex flex-col w-full h-full ${className}`}
      type={type}
    >
      <CardHead type="paddedTop">
        <div className="py-5 px-7 bg-ro-white">
          <MemoizedComponentWrapper>
            <Image
              className="w-full h-full min-h-full aspect-[4/3]"
              image={image}
              aspectRatio="4by3"
              fitIn="true"
            />
          </MemoizedComponentWrapper>
        </div>
      </CardHead>
      <CardBody className="flex flex-col justify-between h-full">
        <div className="mb-6">
          {kicker?.length > 0 && (
            <span className="block mb-2 text-sm text-ro-red-500 group-hover:text-ro-red-700">
              {kicker}
            </span>
          )}
          {title?.length > 0 && (
            <span className="font-bold line-clamp-3">{title}</span>
          )}
          {text?.length > 0 && (
            <span className="mt-2 text-gray-600 line-clamp-4">{text}</span>
          )}
        </div>
        {badge_text?.length > 0 && (
          <div className="">
            <span className="border border-ro-gray-900 rounded-full h-5 text-sm px-2 inline-flex items-center">
              {badge_text}
            </span>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default ProductVariantCard
