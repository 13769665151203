import React, { useState, useEffect, useRef } from "react"

const StoryblokImage = ({
  image,
  className,
  style,
  fixedSize,
  aspectRatio,
  fitIn,
  title,
  alt,
  forceLoad,
  adaptToPixelRatio = false,
}) => {
  const [imageSize, setImageSize] = useState(null)
  const [heightInPercentage, setHeightInPercentage] = useState(null)
  const [loading, setLoading] = useState(true)
  const containerRef = useRef(null)
  const aspectStyle = { aspectRatio: aspectRatio?.replace("by", " / ") } || {}

  const getImageSize = () => {
    const width = containerRef.current.clientWidth
    let dpiFactor = 1
    if (adaptToPixelRatio && window && window.devicePixelRatio) {
      dpiFactor = window.devicePixelRatio
    }
    const contWidth = Math.ceil((dpiFactor * width) / 50) * 50
    if (aspectRatio && aspectRatio !== "") {
      const [ratioWidth, ratioHeight] = aspectRatio.split("by")
      const height = (contWidth / ratioWidth) * ratioHeight
      const percentage = (100 * height) / contWidth
      const contHeight = (dpiFactor * height).toFixed(0)
      setImageSize(`${contWidth}x${contHeight}`)
      setHeightInPercentage(percentage)
      setLoading(false)
    } else {
      setImageSize(`${contWidth}x0`)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      getImageSize()
    }
  }, [containerRef, imageSize, loading])

  if (!image || !image.filename) {
    return <></>
  }

  let originalSrc = image.filename
  /* ? image.filename
    : image.src
    ? image.src
    : image.length > 0 && image
 */
  let imageService = "https://img2.storyblok.com"
  let fileName = originalSrc.replace("https://a.storyblok.com/", "")
  let focus = image.focus
    ? `filters:focal(${image.focus}):format(webp)`
    : "smart/filters:format(webp)"

  let imageSrc = ""

  if (!loading && fixedSize && fitIn) {
    imageSrc = `${imageService}/fit-in/${fixedSize}/filters:fill(ffffff):format(webp)/${fileName}`
  } else if (!loading && !fixedSize && fitIn) {
    imageSrc = `${imageService}/fit-in/${imageSize}/filters:fill(ffffff):format(webp)/${fileName}`
  } else if (!loading && fixedSize && !fitIn) {
    imageSrc = `${imageService}/${fixedSize}/${focus}/${fileName}`
  } else if (!loading && !fixedSize && !fitIn) {
    imageSrc = `${imageService}/${imageSize}/${focus}/${fileName}`
  }

  // todo: move to theme
  // reason: https://www.storyblok.com/docs/image-service
  // sb image service doesnt support svg at all
  if (originalSrc.endsWith(".svg")) {
    imageSrc = originalSrc
  }

  return (
    <div
      ref={containerRef}
      style={aspectRatio && aspectRatio !== "" ? aspectStyle : {}}
      className={`w-full ${className}`}
    >
      {!loading ? (
        <img
          loading={forceLoad ? "eager" : "lazy"}
          src={imageSrc}
          alt={alt}
          className={` ${className ? className : ""}`}
          title={title ? title : alt}
          style={{ ...style, ...aspectStyle }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default StoryblokImage
