import React, { useState, useEffect } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import UserPanel from "../../../../components/molecules/UserPanel"
import { useTranslation } from "react-i18next"

import SearchResultsOverlay from "./SearchResultsOverlay"
import Search from "./Search"
import { Helmet } from "react-helmet"
import Logo from "./Logo"
import MobileMenuSlider from "./MobileMenuSlider.jsx"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUpRightFromSquare,
  faBars,
  faCartShopping,
  faGlobe,
  faLocationDot,
  faSearch,
  faXmark,
} from "@fortawesome/sharp-light-svg-icons"

const websitesMapping = require("../../../../../config/websites.js")
const websites = websitesMapping["websitesMapping"]

// @Redesign: Translation fehlt

const MobileNav = ({
  categories,
  setShowSearchResultsState,
  showSearchResultsState,
  setShowMenuState,
  showMenuState,
  navData,
  setQuery,
  query,
}) => {
  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const [openMainSlider, setOpenMainSlider] = useState(false)
  const [openSubSlider, setOpenSubSlider] = useState(false)

  const { t, i18n } = useTranslation()
  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  // useEffect(() => {
  //   setShowMobileSearch(showSearchResultsState)
  // }, [showSearchResultsState])

  const handleShowMobileSearch = () => {
    setShowMenuState(null)
    setShowMobileSearch(true)
    if (query !== "") {
      setShowSearchResultsState(true)
    }
  }
  const handleHideMobileSearch = () => {
    setShowMenuState(null)
    setShowMobileSearch(false)
    setShowSearchResultsState(false)
  }

  return (
    <div className="block bg-ro-white md:hidden">
      <div className="relative flex flex-row justify-between">
        <Logo className="h-8 px-4 pt-2 my-2" />
        <div className="flex flex-row">
          <button
            className="hover:bg-ro-gray-75 "
            onClick={() =>
              showMobileSearch === true
                ? handleHideMobileSearch()
                : handleShowMobileSearch()
            }
          >
            <div className="flex items-center justify-center w-12 h-12 ml-auto">
              <FontAwesomeIcon
                icon={faSearch}
                size="1x"
                className="inline-block"
              />
            </div>
          </button>

          <button
            className={`w-12 h-12 flex items-center justify-center hover:bg-ro-gray-75 ${
              showMenuState === "mobile" ? "bg-ro-gray-50 " : ""
            } active:bg-ro-gray-50`}
            onClick={() => {
              handleHideMobileSearch()
              setShowMenuState(showMenuState === "mobile" ? null : "mobile")
            }}
          >
            {showMenuState === "mobile" ? (
              <FontAwesomeIcon icon={faXmark} size="1x" className="h-5" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="1x" className="" />
            )}
          </button>
        </div>
        <div
          className={`absolute top-12 z-50 w-full overflow-y-auto flex flex-col transition-opacity duration-150 ${
            showMenuState === "mobile"
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="bg-ro-gray-50 shadow-sm h-[calc(100vh-6rem)]">
            {showMenuState === "mobile" && (
              <Helmet
                htmlAttributes={{
                  class: "overflow-x-hidden overflow-hidden",
                }}
                bodyAttributes={{
                  class:
                    "theme-default font-body overflow-x-hidden overflow-hidden",
                }}
              />
            )}
            {navData && (
              <div className="relative flex flex-col justify-between w-full h-full overflow-hidden">
                <div className="overflow-y-auto">
                  {navData?.map((item, index) => (
                    <MobileMenuSlider
                      key={index}
                      item={item}
                      index={index}
                      openMainSlider={openMainSlider}
                      setOpenMainSlider={setOpenMainSlider}
                      openSubSlider={openSubSlider}
                      setOpenSubSlider={setOpenSubSlider}
                    />
                  ))}
                  <span className="block w-full border-b border-ro-gray-200"></span>
                  <Link
                    link="/"
                    className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75 active:bg-ro-gray-400"
                  >
                    {t("generic.dealer_map.title")}
                    <div className="flex items-center justify-center w-12 h-6 ml-auto">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        size="1x"
                        className="inline-block"
                      />
                    </div>
                  </Link>
                  {/* No Shop Integration */}
                  {/* <Link
                    link="/"
                    className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75 active:bg-ro-gray-400"
                  >
                    Einkaufswagen
                    <div className="flex items-center justify-center w-12 h-6 ml-auto">
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        size="1x"
                        className="inline-block"
                      />
                    </div>
                  </Link> */}
                  <UserPanel mobileVersion={true} />
                  <span className="block w-full border-b border-ro-gray-200"></span>
                  <Link
                    link="https://rothenberger.com/websites"
                    className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75 active:bg-ro-gray-400"
                  >
                    Länderauswahl
                    <div className="flex items-center justify-center w-12 h-6 ml-auto">
                      <FontAwesomeIcon
                        icon={faGlobe}
                        size="1x"
                        className="inline-block"
                      />
                    </div>
                  </Link>
                </div>
                <div className="pb-4 bg-ro-gray-900">
                  <Link
                    className="flex w-full pl-4 pr-0 text-ro-white btn hover:bg-ro-gray-900 hover:border-ro-gray-900 active:bg-ro-gray-700 focus-visible:outline-ro-white whitespace-nowrap"
                    link="/"
                  >
                    Unternehmen und Karriere
                    <div className="flex items-center justify-center w-12 h-6 ml-auto">
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        size="1x"
                        className="inline-block"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`absolute z-50 top-full w-full transition-opacity duration-150 ${
            showMobileSearch ? "opacity-100" : "opacity-0"
          }`}
        >
          {showMobileSearch && (
            <>
              <Search
                setShowMenuState={setShowMenuState}
                showMenuState={showMenuState}
                categories={categories}
                setShowSearchResultsState={setShowSearchResultsState}
                showSearchResultsState={showSearchResultsState}
                setShowMobileSearchState={setShowMobileSearch}
                setQuery={setQuery}
                query={query}
                isMobile={true}
              />
            </>
          )}
        </div>
        {/* Search Resulsts */}
        <SearchResultsOverlay
          isMobile={true}
          categories={categories}
          setShowSearchResultsState={setShowSearchResultsState}
          showSearchResultsState={showSearchResultsState}
        />
      </div>
    </div>
  )
}

export default MobileNav
