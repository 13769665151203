import React, { Fragment, useState, useEffect } from "react"

import { Menu, Transition } from "@headlessui/react"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRightFromBracket,
  faUser,
  faCircleDashed,
  faEyeSlash,
} from "@fortawesome/sharp-light-svg-icons"
import { faUser as faUserFilled } from "@fortawesome/sharp-solid-svg-icons"

// @Redesign: Translation anpassen!

const loginAssistentUrl = (redirectUri, websiteKey, i18n) => {
  return `${process.env.GATSBY_LOGIN_ASSISTENT_HOST}/${i18n.language}/login?sp_name=${process.env.GATSBY_LOGIN_ASSISTENT_SP_NAME}&website=${websiteKey}&redirect=${redirectUri}`
}

const UserLoading = () => {
  return (
    <div className="flex px-4 py-3 lg:py-0 btn lg:block lg:w-12 lg:h-12 lg:px-0">
      <div className="flex items-center justify-center w-12 h-12">
        <FontAwesomeIcon
          icon={faCircleDashed}
          size="1x"
          className="inline-block animate-spin w-4"
        />
      </div>
    </div>
  )
}

const UserPanelInPreview = () => {
  return (
    <div className="flex items-center justify-center w-12 h-12">
      <FontAwesomeIcon icon={faEyeSlash} size="1x" className="inline-block" />
    </div>
  )
}

const UserLoggedIn = ({ mobileVersion, user, logout, t }) => {
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className={`w-full block ${open ? "text-ro-red" : ""}`}>
            <div className="items-center justify-center block w-full px-4 py-3 text-right lg:px-0 lg:py-0 lg:flex lg:w-12 lg:h-12 hover:text-ro-red">
              {mobileVersion && <span>{user.name}&nbsp;</span>}
              <FontAwesomeIcon
                icon={faUserFilled}
                size="1x"
                className="inline-block w-4 h-4 -mt-0.5 align-middle"
              />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-50 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-50 opacity-0"
          >
            <Menu.Items
              as="ul"
              className={`absolute right-0 left-auto z-50 min-w-full px-4 shadow-lg bg-ro-gray-50 whitespace-nowrap origin-top-right${
                mobileVersion ? " top-0 -translate-y-full" : " top-full"
              }`}
            >
              <Menu.Item key={user.name} disabled as="li">
                <div className="block w-full px-0 text-right text-ro-gray-400 btn">
                  {t("generic.welcome")} {user.name}
                </div>
              </Menu.Item>

              <Menu.Item key="user-logout" as="li">
                <div
                  onClick={() =>
                    logout({
                      returnTo: `${window.location.origin}/${
                        window.location.pathname.split("/")[1]
                      }`,
                    })
                  }
                  className="block w-full px-0 text-right cursor-pointer text-ro-gray-900 btn hover:text-ro-red"
                >
                  {t("generic.logout")}
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    size="1x"
                    className="inline-block w-4 h-4 ml-2 -mt-0.5 align-middle"
                  />
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

// const UserAnonymous = ({ mobileVersion, loginWithRedirect }) => {
//   return (
//     <button onClick={() => redirectToLogin()} className="btn hover:text-ro-red">
//       {mobileVersion && (
//         <span>Login&nbsp;</span>
//       )}
//       <UserAvatar20 className="inline-block -mt-0.5 align-middle" />
//     </button>
//   )
// }

const UserPanel = ({ mobileVersion }) => {
  const [redirectUri, setRedirectUri] = useState("")
  const [inPreviewMode, setInPreviewMode] = useState(false)
  const { i18n, t } = useTranslation()
  const [websiteKey, setWebsiteKey] = useState(null)

  useEffect(() => {
    if (window && window.location && window.location.pathname) {
      setRedirectUri(window.location.pathname)
      if (
        window?.location?.pathname === "/storyblok-preview" ||
        window?.location?.pathname === "/storyblok-preview/"
      ) {
        setInPreviewMode(true)
      }
    }
    if (window && window.location && window.location.pathname) {
      setRedirectUri(window.location.pathname)
    }
    if (process.env.GATSBY_GLOBAL_PATH_PREFIX !== undefined) {
      setWebsiteKey(process.env.GATSBY_GLOBAL_PATH_PREFIX)
    } else {
      if (window && window.location && window.location.pathname) {
        setWebsiteKey(window.location.pathname.split("/")[1].split("-")[0])
      }
    }
  }, [])
  const { user, isAuthenticated, logout, isLoading } = useAuth0()

  if (inPreviewMode) return <UserPanelInPreview />
  if (isLoading) return <UserLoading />
  if (isAuthenticated)
    return (
      <UserLoggedIn
        mobileVersion={mobileVersion}
        user={user}
        logout={logout}
        t={t}
      />
    )

  return (
    <a
      className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75 lg:block lg:w-12 lg:h-12 lg:px-0 lg:py-0 lg:active:border-b lg:hover:bg-ro-gray-100 lg:hover:border-b-ro-gray-100 lg:active:bg-ro-gray-200"
      href={loginAssistentUrl(redirectUri, websiteKey, i18n)}
      title="Login"
    >
      {mobileVersion && <span>{t("generic.login")}&nbsp;</span>}
      <div className="flex items-center justify-center w-12 h-6 ml-auto lg:h-12 lg:ml-0">
        <FontAwesomeIcon icon={faUser} size="1x" className="inline-block" />
      </div>
    </a>
  )
}

export default UserPanel
