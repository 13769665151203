import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

const SearchResultCard = ({ item }) => {
  if (item.image.filename === null) {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  // @Redesign: Translation fehlt

  return (
    <Link
      className="flex flex-col w-full transition-colors duration-300 bg-ro-gray-100 border border-ro-gray-300 hover:bg-ro-gray-200"
      link={item.link}
    >
      <div className="block w-full aspect-[4/3]">
        <Image
          className="w-full h-auto min-h-full"
          image={item.image}
          aspectRatio="4by3"
          fillColor="ffffff"
        />
      </div>
      <div className="flex flex-col justify-between h-full p-4 text-sm">
        <div>
          <span className="mb-1 font-bold hyphens-auto line-clamp-2">
            {item.headline}
          </span>
          {item?.text?.length > 0 && (
            <p className="text-ro-gray-600 line-clamp-2">{item.text}</p>
          )}
        </div>
        <div className="mt-6 text-ro-red">
          <span>Mehr erfahren</span>
          <FontAwesomeIcon
            icon={faArrowRight}
            size="1x"
            className="self-center inline-block ml-2"
          />
        </div>
      </div>
    </Link>
  )
}

export default SearchResultCard
