import React from "react"
import { tv } from "tailwind-variants"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const cardVariants = tv({
  base: "border group transition-colors duration-300",
  variants: {
    type: {
      white:
        "bg-ro-white border-ro-gray-300 hover:bg-ro-gray-75 active:outline active:outline-ro-red active:outline-2 active:-outline-offset-2 focus:outline focus:outline-ro-red focus:outline-2 focus:-outline-offset-2",
      gray: "bg-ro-gray-50 border-ro-gray-200 hover:bg-ro-gray-75 hover:border-ro-gray-300 active:outline active:outline-ro-red active:outline-2 active:-outline-offset-2 focus:outline focus:outline-ro-red focus:outline-2 focus:-outline-offset-2",
    },
  },
  defaultVariants: { type: "gray" },
})

export const Card = ({ children, className, type, link, target, ...props }) => {
  return (
    <Link
      className={cardVariants({
        type: type,
        className: className,
      })}
      link={link}
      target={target}
      {...props}
    >
      {children}
    </Link>
  )
}

const cardHeadVariants = tv({
  base: "",
  variants: {
    type: {
      default: "",
      paddedTop: "pt-4 px-4",
    },
  },
  defaultVariants: { type: "default" },
})

export const CardHead = ({ children, className, type = "default" }) => {
  return (
    <div
      className={cardHeadVariants({
        type: type,
        className: className,
      })}
    >
      {children}
    </div>
  )
}

const cardBodyVariants = tv({
  base: "",
  variants: {
    type: {
      default: "p-4",
      bigPadded: "p-4 lg:p-7",
    },
  },
  defaultVariants: { type: "default" },
})

export const CardBody = ({ children, className, type = "default" }) => {
  return (
    <div
      className={cardBodyVariants({
        type: type,
        className: className,
      })}
    >
      {children}
    </div>
  )
}
