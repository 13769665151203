import React from "react"
import ReactMarkdown from "react-markdown"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { tv } from "tailwind-variants"

const textVariants = tv({
  base: "prose max-w-none text-ro-gray-900",
  variants: {
    type: {
      default: "",
      flow: "sm:columns-2 sm:gap-8",
    },
  },
})

const Richtext = ({ text, flow, className }) => {
  const LinkComponent = ({ children, href }) => (
    <Link link={href}>{children}</Link>
  )

  return (
    <>
      {text && text.length > 0 && (
        <ReactMarkdown
          children={text}
          className={textVariants({
            type: flow ? "flow" : "default",
            className: className,
          })}
          components={{ a: LinkComponent }}
        />
      )}
    </>
  )
}

export default Richtext
