import React from "react"
import HeaderNew from "./HeaderNew"
import HeaderOld from "./HeaderOld"

const Header = ({ navData }) => {
  // force new navigation by ENV constant to avoid flicker
  if (process.env.GATSBY_USE_V3_NAVIGATION === "true") {
    return (
      <HeaderNew navData={navData?.newMainNavigation?.navigation || null} />
    )
  } else {
    if (navData) {
      if (navData?.newMainNavigation?.new_design === true) {
        return <HeaderNew navData={navData?.newMainNavigation?.navigation} />
      } else {
        return <HeaderOld navData={navData?.mainNavigation?.navigation} />
      }
    }
  }
}

export default Header
