import React from "react"
import FooterWithItems from "./FooterWithItems"
import FooterWithColumns from "./FooterWithColumns"

const Footer = ({ navData }) => {
  const footerKey = process.env.GATSBY_FOOTER_NAV_KEY || "footer"

  if (navData && navData[footerKey]) {
    if (navData[footerKey].navigation) {
      return <FooterWithItems navData={navData[footerKey].navigation} />
    } else if (navData?.footer?.columns) {
      return <FooterWithColumns navData={navData[footerKey]} />
    } else {
      return <div></div>
    }
  } else {
    return null
  }
}

export default Footer
