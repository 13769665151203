import React, { useState, useEffect, useContext } from "react"
import MetaNav from "./MetaNav"
import Search from "./Search"
import SearchResultsOverlay from "./SearchResultsOverlay"
import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"
import MainNav from "./MainNav"
import Logo from "./Logo"

import MobileNav from "./MobileNav"

import useMediaQuery from "@yumgmbh/gatsby-theme-yum-components/src/hooks/useMediaQuery"

import UserPanel from "../../../../components/molecules/UserPanel"

import queryString from "query-string"
import algoliasearch from "algoliasearch"
import {
  InstantSearch,
  Configure,
  hits,
  connectHits,
  connectMenu,
} from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCartShopping,
  faLocationDot,
} from "@fortawesome/sharp-light-svg-icons"

export const ALGOLIA_HITS_PER_PAGE = 10
export const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const VirtualMenu = connectMenu(() => null)

const HeaderNew = ({ navData }) => {
  const navContext = useContext(NavContext)
  const [showMenuState, setShowMenuState] = useState(null)
  const [showSearchResultsState, setShowSearchResultsState] = useState(false)

  const { t, i18n } = useTranslation()

  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  // Algolia Search
  let initialQuery = ""
  if (typeof window !== "undefined") {
    initialQuery = queryString.parse(window.location.search).query || ""
  }
  if (initialQuery) {
    if (showSearchResultsState !== true) {
      setShowSearchResultsState(true)
    }
  }
  const [query, setQuery] = useState(initialQuery)
  const options = {
    ...{
      query,
      filters: "",
      attributesToSnippet: ["name", "description:30"],
      //facetingAfterDistinct: true,
      hitsPerPage: 1000,
    },
  }

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return algoliaClient.search(requests)
    },
  }
  // END of Algolia Search

  let isDesktop = useMediaQuery(`(min-width: 672px)`)

  useEffect(() => {
    if (window && document) {
      gsap.registerPlugin(ScrollTrigger)

      let mainHeader = document.querySelector(".header-main")

      if (mainHeader) {
        let mainOffset = mainHeader.offsetHeight
        let mainElement = document.querySelector("main")

        ScrollTrigger.create({
          start: `top ${isDesktop ? "-48" : "0"}`,
          end: 99999,
          onEnter: () => {
            mainElement.style.paddingTop = `${isDesktop ? 192 : 96}px`
            mainHeader.classList.add("header-main-fixed")
          },
          onLeaveBack: () => {
            mainElement.style.paddingTop = null
            mainHeader.classList.remove("header-main-fixed")
          },
        })

        // only show when scrolling up
        // ScrollTrigger.create({
        //   start: "top -400",
        //   end: 99999,
        //   onUpdate: ({ direction }) => {
        //     if (direction == -1) {
        //       mainHeader.classList.add("header-main-fixed-show")
        //     } else {
        //       mainHeader.classList.remove("header-main-fixed-show")
        //     }
        //   },
        // })
      }
    }
  }, [])

  let categories = [{ title: "Universalwerkezeuge" }, { title: "Wartung" }]

  return (
    <header className="w-full header-main">
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      >
        <Configure
          query={options.query}
          filters={options.filters}
          facetingAfterDistinct={options.facetingAfterDistinct}
          attributesToSnippet={options.attributesToSnippet}
          hitsPerPage={options.hitsPerPage}
          distinct
        />
        {navContext && navContext.websiteKey && (
          <VirtualMenu
            attribute="website"
            defaultRefinement={navContext.websiteKey}
          />
        )}
        {/* Meta-Nav */}
        <div className="h-12">
          <MetaNav />
        </div>
        {/* ENDE Meta-Nav */}
        {navData && (
          <MobileNav
            categories={categories}
            setShowSearchResultsState={setShowSearchResultsState}
            showSearchResultsState={showSearchResultsState}
            setShowMenuState={setShowMenuState}
            showMenuState={showMenuState}
            setQuery={setQuery}
            query={query}
            navData={navData}
          />
        )}
        {/* Logo-Search-Bar */}
        <div className="relative hidden w-full border-b bg-ro-white border-ro-gray-100 md:block">
          <div className="flex flex-row mx-auto lg:px-10 max-w-screen-2xl">
            <div className="flex flex-row py-4 mr-auto">
              <Logo className="h-12 pt-3.5 pb-1 px-grid lg:pr-4 lg:pl-0" />
            </div>
            <Search
              setShowMenuState={setShowMenuState}
              showMenuState={showMenuState}
              categories={categories}
              setShowSearchResultsState={setShowSearchResultsState}
              showSearchResultsState={showSearchResultsState}
              setQuery={setQuery}
              query={query}
            />
            {/* ENDE Suche */}
            <div className="flex flex-row py-4 pl-4 -ml-4">
              {dealerLinks && dealerLinks[i18n.language] && (
                <Link
                  link={dealerLinks[i18n.language]}
                  className="inline-block w-12 h-12 active:border-b hover:bg-ro-gray-100 hover:border-b-ro-gray-100 active:bg-ro-gray-200 "
                >
                  <div className="flex items-center justify-center w-12 h-12">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      size="1x"
                      className="inline-block h-4"
                    />
                  </div>
                </Link>
              )}
              {/* No Shop Integration */}
              {/* <Link
                link={dealerLinks[i18n.language]}
                className="inline-block w-12 h-12 active:border-b hover:bg-ro-gray-100 hover:border-b-ro-gray-100 active:bg-ro-gray-200"
              >
                <div className="flex items-center justify-center w-12 h-12">
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    size="1x"
                    className="inline-block"
                  />
                </div>
              </Link> */}
              <UserPanel />
            </div>
          </div>
          {/* Search Resulsts */}
          <SearchResultsOverlay
            categories={categories}
            setShowSearchResultsState={setShowSearchResultsState}
            showSearchResultsState={showSearchResultsState}
            query={query}
          />
          {/* END Search Resulsts */}
        </div>
        <div className="relative hidden w-full h-16 bg-ro-white md:block">
          {/* Main-Nav */}
          {navData && (
            <MainNav
              showMenuState={showMenuState}
              setShowMenuState={setShowMenuState}
              navData={navData}
            />
          )}
        </div>
        {/* ENDE Logo-Search-Bar */}
      </InstantSearch>
    </header>
  )
}

export default HeaderNew
